<template>
  <div class="card card-custom card-stretch">
    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />
    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ items.length }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="invoice-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="ErrorTable"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :filter="filters"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="errorTable"
    >
      
      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">
          <a v-if="row.item.id && row.item.type === 'MEMBER'" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="member_selected(row.item.id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="help_text_clicked(row.item.reason)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-question-circle color-primary"></i>
            </span>
          </a>

        </div>
      </template>

    </b-table>
  

  </div>

</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';


import { mapGetters } from 'vuex';

export default {
  name: 'ErrorTable',
  mixins: [ toasts ],
  components: {

  },

  computed: {
    total_rows() {
      return this.items.length;
    },
    ...mapGetters(['currentPeriodId', 'periods', 'customer']),
  },
  mounted() {
    this.fetch_errors();
  },
  watch: {
    
  },
  methods: {

    member_created(item) {},

    member_updated(item) {},

    member_deleted(item) {},

    help_text_clicked(reason) {
      window.open(`https://memlist.se/docs/felrapport#${reason.toLowerCase()}`, '_blank');
    },

    async fetch_errors() {
      try {
        const loader = this.$loading.show();

        const res = await axios.get(`/error/report/${this.customer.id}`);

        if (res.status === 200) {
          this.items = res.data;
          loader && loader.hide();
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ERROR.UNABLE_GET'));
      }
      catch (err) {
        console.error('fetch_errors error', err);
      }

      loader && loader.hide();
    },

    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show(member_id);
    },

  },
  data() {
    return {
      items: [],
      member_id: null,
      orderBy: { created_at: 'desc' },
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],

      filters: {
        
      },
      selected: [],
      fields: [
        {
          key: 'reason',
          label: this.$t('ERROR.REASON'),
          sortable: true,
          formatter: (_, __, item) => {
            return this.$t('COMPANY_SETTINGS.' + item.reason.toUpperCase());
          },
          tdClass: 'td-overflow w-50',
          thClass: 'td-overflow w-50'
        },
        {
          key: 'value',
          label: this.$t('ERROR.VALUE'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  }
};
</script>
